<template>
    <footer class="footer">
        <div class="footer__bg">
            <div class="footer__container container grid">
                <div>
                    <h1 class="footer__title">Abdul Mufid</h1>
                    <span class="footer__subtitle">Backend Developer</span>
                </div>

                <ul class="footer__links">
                    <li>
                        <a href="#services" class="footer_link">Services</a>
                    </li>
                    <li>
                        <a href="#portfolio" class="footer_link">Portofolio</a>
                    </li>
                    <li>
                        <a href="#contact" class="footer_link">Contact Me</a>
                    </li>
                </ul>

                <div class="footer__socials">
                    <a href="https://www.facebook.com/eabdalmufid" class="footer__social" target="__blank">
                        <i class="uil uil-facebook-f"></i>
                    </a>

                    <a href="https://www.instagram.com/eabdlmufid" class="footer__social" target="__blank">
                        <i class="uil uil-instagram"></i>
                    </a>

                    <a href="https://twitter.com/eabdalmufid" class="footer__social" target="__blank">
                        <i class="uil uil-twitter-alt"></i>
                    </a>
                </div>
            </div>

            <p class="footer__copy">&#169; Abdul Mufid • All Right Reserved</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>
