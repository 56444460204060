<template>
    <section class="portfolio section" id="portfolio">
        <h2 class="section__title">Portofolio</h2>
        <span class="section__subtitle">Most recent work</span>

        <div class="portofolio__container container swiper">
            <div class="swiper-wrapper">
                <!--==================== PORTFOLIO 1 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/1.jpg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Modern Course Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://iqrafun.com/" class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 2 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/2.jpg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Modern Wedding Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://eabdalmufid.github.io/undangan/"
                            class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 3 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/3.jpg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Modern Rest API Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://apiordiston.onrender.com/"
                            class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 4 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/4.jpg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Whatsapp Bot Payment Gateway</h3>
                        <p class="portofolio__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi luctus pretium magna, id tincidunt mi venenatis at.
                        </p>
                    </div>
                </div>

                <!--==================== PORTFOLIO 5 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/5.jpg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Telegram Bot Islami</h3>
                        <p class="portofolio__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi luctus pretium magna, id tincidunt mi venenatis at.
                        </p>
                    </div>
                </div>

                
            </div>
            
            <!-- add arrows -->
            <div class="swiper-button-next">
                <i class="uil uil-angle-right-b swiper-portofolio-icon"></i>
            </div>
            <div class="swiper-button-prev">
                <i class="uil uil-angle-left-b swiper-portofolio-icon"></i>
            </div>

            <!-- add pagination -->
            <div class="swiper-pagination"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Portfolio'
}
</script>