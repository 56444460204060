<template>
    <section class="skills section" id="skills">
        <h2 class="section__title">Skills</h2>
        <span class="section__subtitle">My technical level</span>

        <div class="skills_container container grid">
            <div>
                <!--==================== SKILLS 1 ====================-->
                <div class="skills__content skills__open">
                    <div class="skills__header">
                        <i class="uil uil-server-network skills__icon"></i>

                        <div>
                            <h1 class="skills__titles">Backend developer</h1>
                            <span class="skills__subtitle">More than 3 years</span>
                        </div>

                        <i class="uil uil-angle-down skills__arrow"></i>
                    </div>

                    <div class="skills__list grid">

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Node Js</h3>
                                <span class="skills__number">80%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__node"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Express Js</h3>
                                <span class="skills__number">80%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__express"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Python</h3>
                                <span class="skills__number">75%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__python"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Java</h3>
                                <span class="skills__number">70%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__java"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">NoSQL</h3>
                                <span class="skills__number">70%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__nosql"></span>
                            </div>
                        </div>

                    </div>
                </div>
                
                <!--==================== SKILLS 2 ====================-->
                <div class="skills__content skills__close">
                    <div class="skills__header">
                        <i class="uil uil-brackets-curly skills__icon"></i>

                        <div>
                            <h1 class="skills__titles">Frontend developer</h1>
                            <span class="skills__subtitle">More than 1 years</span>
                        </div>

                        <i class="uil uil-angle-down skills__arrow"></i>
                    </div>

                    <div class="skills__list grid">
                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Tailwind</h3>
                                <span class="skills__number">50%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__tailwind"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Bootstrap</h3>
                                <span class="skills__number">70%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__bootstrap"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">JavaScript</h3>
                                <span class="skills__number">80%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__javascript"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Vue</h3>
                                <span class="skills__number">40%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__vue"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <!--==================== SKILLS 3 ====================-->
                <div class="skills__content skills__close">
                    <div class="skills__header">
                        <i class="uil uil-share-alt skills__icon"></i>

                        <div>
                            <h1 class="skills__titles">DevOps</h1>
                            <span class="skills__subtitle">More than 2 years</span>
                        </div>

                        <i class="uil uil-angle-down skills__arrow"></i>
                    </div>

                    
                    <div class="skills__list grid">
                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Github</h3>
                                <span class="skills__number">80%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__github"></span>
                            </div>
                        </div>

                    </div>
            
                </div>

                <!--==================== SKILLS 4 ====================-->
                <div class="skills__content skills__close">
                    <div class="skills__header">
                        <i class="uil uil-swatchbook skills__icon"></i>

                        <div>
                            <h1 class="skills__titles">Designer</h1>
                            <span class="skills__subtitle">More than 3 years</span>
                        </div>

                        <i class="uil uil-angle-down skills__arrow"></i>
                    </div>

                    
                    <div class="skills__list grid">
                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Canva</h3>
                                <span class="skills__number">90%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__canva"></span>
                            </div>
                        </div>

                    </div>
            
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Skills'
}

</script>